import { useStaticQuery, graphql } from 'gatsby';
import { drupalTransformer } from '../libs/transform/index';

export const useCateringQuery = () => {
  return drupalTransformer.transformNode(useCatering());
};

const useCatering = () => {
  return useStaticQuery(graphql`
      query useCateringQuery {
          hero: configPagesCatering {
              body: field_hero_description {
                  value
              }
              title: field_hero_title {
                  value
              }
              relationships {
                  image: field_hero_image {
                      localFile {
                          childImageSharp {
                              fluid {
                                  base64
                                  aspectRatio
                                  srcSet
                                  src
                                  sizes
                              }
                          }
                      }
                  }
              }
          }
          featuredCta: configPagesCatering {
              relationships {
                  content: field_featured_cta {
                      title: field_title {
                          value
                      }
                      label: field_subtitle {
                          value
                      }
                      link: field_link {
                          title
                          uri
                      }
                      text: field_description {
                          value
                      }
                      relationships {
                          cmsImage: field_featured_cta_image {
                              localFile {
                                  childImageSharp {
                                      fluid {
                                          base64
                                          aspectRatio
                                          src
                                          sizes
                                          srcSet
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
          madeToOrder: configPagesCatering {
              title: field_made_to_order_title {
                  value
              }
              relationships {
                  blocks: field_catering_made_to_order {
                      relationships {
                          field_image {
                              localFile {
                                  childImageSharp {
                                      fluid {
                                          srcSet
                                          src
                                          sizes
                                          aspectRatio
                                          base64
                                      }
                                  }
                              }
                          }
                      }
                      field_title {
                          value
                      }
                      field_description {
                          value
                      }
                  }
              }
          }
      }
  `);
};
