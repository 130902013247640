// Libs
import React, { useMemo } from 'react';
// Assets
import styles from './catering-hero.module.scss';
import * as PropTypes from 'prop-types';
// Components
import ImageHero from '../common/image-hero/image-hero';

const CateringHero = ({ header }) => {
  return useMemo(
    () => (
      <div className={styles.cateringHero}>
        <ImageHero data={header} />
      </div>
    ),
    [header]
  );
};

CateringHero.defaultProps = {
  header: PropTypes.Object,
};

export default CateringHero;
