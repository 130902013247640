// Libs
import React, { useMemo } from 'react';
// Assets
import styles from './catering-order.module.scss';
// Components
import FindStoreCta from '../common/find-store-cta/find-store-cta';

const CateringOrder = ({ cta }) => {
  return (
    <div className={styles.cateringOrder}>
      <FindStoreCta cta={cta}/>
    </div>
  );
};

export default CateringOrder;
