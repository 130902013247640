// Libs.
import React from 'react';
// Assets.
import styles from './catering-landing.module.scss';
import { header, feature, tiles, cta } from '../../mocks/catering.json';
// Components.
import CateringHero from './catering-hero';
import CateringFeature from './catering-feature';
import CateringFresh from './catering-fresh';
import CateringOrder from './catering-order';
import { useCateringQuery } from '../../hooks/useCateringQuery';

const CateringLanding = () => {
  const data = useCateringQuery();

  // console.log('Catering', data);

  return (
    <div className={styles.cateringGuideBackground}>
      <CateringHero header={data.hero} />
      <CateringFeature feature={data.featuredCta.content} />
      <CateringFresh title={data.madeToOrder.title} tiles={data.madeToOrder.blocks} />
      <CateringOrder cta={cta} />
    </div>
  );
};

export default CateringLanding;
