// Libs.
import React from 'react';
// Components.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import CateringLanding from '../components/catering/catering-landing';

const Catering = () => {
  return (
    <Layout>
      <SEO title="Catering Guide" />
      <CateringLanding />
    </Layout>
  );
};

export default Catering;
