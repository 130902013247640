// Libs
import React, { useMemo } from 'react';
// Assets
import styles from './catering-feature.module.scss';
import * as PropTypes from 'prop-types';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import ImageFeatureArea from '../common/image-feature-area/image-feature-area';

const CateringFeature = ({ feature }) => {
  const image = useImageStaticQuery(feature.image, feature.cmsImage);

  return useMemo(
    () => (
      // todo need to update the image.
      <div className={styles.cateringFeatureWrapper}>
        <div className={styles.cateringFeature}>
          <ImageFeatureArea data={feature} imageFluid={image}/>
        </div>
      </div>
    ),
    [feature],
  );
};

CateringFeature.defaultProps = {
  feature: PropTypes.isObject,
  imageFluid: PropTypes.isObject,
};

export default CateringFeature;
