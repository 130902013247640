// Libs
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';
// Assets
import styles from './catering-fresh.module.scss';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import Tiles from '../common/tiles/tiles';
import { tilesTitle } from '../../mocks/catering.json';
import Tile from '../common/cards/tile';
import locale from '../../locale/locale';

const CateringFresh = ({ title, tiles }) => {
  const [showMobile, setShowMobile] = useState(false);

  return (
    <div className={styles.cateringFresh} data-show={showMobile}>
      <h2>{title || tilesTitle}</h2>
      <Tiles columns={4}>
        {tiles &&
        tiles.map((tile, i) => {
          return <Tile image={tile.image} title={tile.title} description={tile.description} key={i}/>;
        })}
      </Tiles>
      {!showMobile && (
        <div className={styles.tilesListLoadMore}>
          <button
            tabIndex="0"
            className={styles.tilesListLoadMoreLink}
            onClick={() => {
              setShowMobile(true);
            }}
          >
            {locale.loftLandingPage.buttons.loadMoreButton}
          </button>
        </div>
      )}
    </div>
  );
};

CateringFresh.defaultProps = {
  tiles: PropTypes.isObject,
};

export default CateringFresh;
